<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { cloneDeep } from 'lodash-es'
import Vue3RuntimeTemplate from 'vue3-runtime-template'
import type { SbHeadlineStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbHeadlineStoryblok }>()

const schema = cloneDeep(RichTextSchema)

const renderedTitle = computed(() => props.blok.title?.content?.length ? renderRichText(props.blok.title) : null)
const renderedTitleSize = computed(() => props.blok.titleSize || 'h1')

const renderedSubtitle = computed(() => renderRichText(props.blok.subtitle))

const renderedText = computed(() => props.blok.text?.content?.length
  ? renderRichText(props.blok.text, {
    schema,
    resolver: (component, blok) => {
      switch (component) {
        case 'SBButtonGroup':
          return `<component is="${component}" :blok='${JSON.stringify(blok)}' />`
        case 'SBImage':
          return `<component is="${component}" :blok='${JSON.stringify(blok)}' />`
        default:
          return `<div class="text-xs px-1.5 py-0.5 bg-red-500 text-white inline-block">Resolver for <span class="underline font-highlighted">${component}</span> not defined</div>`
      }
    },
  })
  : null,
)

const textAlignClass = computed(() => {
  switch (props.blok.textAlign) {
    case 'center':
      return 'lg:text-center'

    case 'right':
      return 'lg:text-right'

    case 'left':
      return 'lg:text-left'

    default:
      return 'lg:text-left'
  }
})

const textAlignMarginClass = computed(() => {
  switch (props.blok.textAlign) {
    case 'center':
      return 'lg:mx-auto'

    case 'right':
      return 'lg:ml-auto'

    default:
      return ''
  }
})
</script>

<template>
  <div
    v-editable="blok"
    class="flex flex-col gap-y-5"
    :class="textAlignClass"
  >
    <div class="flex flex-col gap-y-3">
      <TypographyStyledText v-if="renderedTitle || renderedSubtitle" class="order-2">
        <BaseSanitizedHTML
          v-if="renderedTitle"
          :allowed-tags="['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'b', 'strong', 'br']"

          :html="renderedTitle"
        >
          <template #default="{ html }">
            <CfgTypography :size="CfgTypographySizes[renderedTitleSize]" tag-name="span">
              <Vue3RuntimeTemplate :template="html" />
            </CfgTypography>
          </template>
        </BaseSanitizedHTML>
      </TypographyStyledText>

      <BaseSanitizedHTML
        v-if="renderedSubtitle"
        :allowed-tags="['span', 'b', 'strong']"
        :html="renderedSubtitle"
      >
        <template #default="{ html }">
          <CfgTypography
            class="text-grey-300"
            :class="{
              'order-3': blok.subtitlePlacement === 'below',
              'order-1': blok.subtitlePlacement === 'above',
            }"
            :html="renderedSubtitle"
            :size="CfgTypographySizes.h4"
            tag-name="span"
          >
            <Vue3RuntimeTemplate class="order-2" :template="html" />
          </CfgTypography>
        </template>
      </BaseSanitizedHTML>
    </div>

    <TypographyStyledText
      v-if="renderedText"
      class="!max-w-[650px]"
      :class="[textAlignClass, textAlignMarginClass]"
    >
      <Vue3RuntimeTemplate :template="renderedText" />
    </TypographyStyledText>
  </div>
</template>
